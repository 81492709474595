<template>
    <div class="service-charge-record">
        <div class="service-charge-record-title">
           <span v-if="title"> {{  title  }} | </span>  劳务费发放记录
        </div>
        <div class="table-ctn">
            <a-table rowKey="issuanceTime" :columns="columns" :data-source="list" :pagination="false" size="small"
                class="exper-table" :scroll="{ x: 1100 }">
                <template slot="index" slot-scope="text, record, index">
                    {{ index + 1 }}
                </template>
                <template slot="memberType" slot-scope="text, record, index">
                    {{ getProjectMemberTypes(record.memberType) }}
                </template>
                <template slot="issuanceTime" slot-scope="text, record, index">
                    {{ text.split(' ')[0] }}
                </template>
                <template slot="hasFile" slot-scope="text, record, index">
                    <a-button type="link" v-if="record.hasFile === 1" @click="checkFile(record)">查看凭证</a-button>
                </template>
            </a-table>
        </div>
        <div class="default-pagination">
            <a-pagination size="small" @change="handlePageChange" v-model="current" :pageSize="10" :total="totalRows"
                show-less-items />
        </div>
        <CheckFileModal v-if="checkFileModalVisible" :visible.sync="checkFileModalVisible" :recordId="checkFileRecord.id" :projectId="projectId">
        </CheckFileModal>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue-demi';
import { getLaborCostHistory } from '../../../api/projectUserManager';
import { getProjectMemberTypes } from '../../../utils/enums';
import CheckFileModal from '../components/checkFileModal.vue';
const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        width: 65,
        scopedSlots: { customRender: "index" }
    },
    {
        title: '收款人',
        dataIndex: 'payeePersonName'
    },
    {
        title: '手机号',
        dataIndex: 'payeePersonPhone'
    },
    {
        title: '发款人',
        dataIndex: 'operatorName'
    },
    {
        title: '发放时间',
        dataIndex: 'issuanceTime',
         scopedSlots: { customRender: "issuanceTime" }
    },
    {
        title: '发放金额（元）',
        dataIndex: 'issuanceMoney'
    },
    {
        title: '收款人角色',
        scopedSlots: { customRender: "memberType" }
    },
    {
        title: '发款凭证',
        width: 150,
        fixed: 'right',
        scopedSlots: { customRender: "hasFile" }
    }
]

export default {
    props: {},
    setup(props, context) {
        // 项目id
        const projectId = ref(context.root.$route.query.id ? context.root.$route.query.id : null);
        // 列表
        const list = ref([]);
        // 
        const getRecord = async () => {
            const res = await getLaborCostHistory({
                projectid: projectId.value,
                pagesize: 10,
                pagenum: current.value
            });
            if (res.code === 200) {
                list.value = res.data.data;
                totalRows.value = res.data.count;
            }
        };

        const title = context.root.$route.query.title ? context.root.$route.query.title : '';
        onMounted(async () => {
            if(title) {
                document.title = `${title} | 劳务费发放记录 | 科研诚信管理系统`
            }
            getRecord();
        });
        const current = ref(1);
        const totalRows = ref(0);
        const handlePageChange = (page, pageSize) => {
            getRecord();
            return;
        };
        // 查看凭证对话框
        const checkFileModalVisible = ref(false);
        const checkFileRecord = ref({});
        const checkFile = (record) => {
            checkFileRecord.value = record;
            checkFileModalVisible.value = true;
        };
        return {
            getProjectMemberTypes,
            list,
            columns,
            current,
            totalRows,
            handlePageChange,
            checkFile,
            checkFileModalVisible,
            checkFileRecord,
            projectId,
            title
        };
    },
    components: { CheckFileModal }
}
</script>

<style lang="less" scoped>
.service-charge-record {
    background: #FFFFFF;
    min-height: calc(100vh - 100px);
    padding: 16px;
    color: rgba(0, 0, 0, 0.85);

    .service-charge-record-title {
        text-align: left;
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 16px;
        border-bottom: 1px solid #EBEBEB;
    }

    .table-ctn {
        min-height: calc(100vh - 200px);
        margin-top: 16px;

        /deep/ .ant-table-thead {
            background: #F0F5FF;
        }

        /deep/ .ant-table-small>.ant-table-content>.ant-table-body {
            margin: 0px;
        }

        /deep/ .ant-table-row {
            td {
                padding: 7px;
            }
        }
    }
    .default-pagination {
		text-align: center;
		margin-bottom: 24px;
	}
}
</style>
<template>
    <a-modal :visible.sync="visible" :width="680" title="发款凭证" @cancel="handleCancel" :footer="null" >
        <a-spin :spinning="loading">
            <a-form-model   :colon="false" >
                <a-form-model-item label="">
                    <div style="display: flex; flex-wrap: nowrap;">
                        <ImageWithPreview v-if="imageData.transferVoucherone" :src="imageData.transferVoucherone" ></ImageWithPreview>
                        <ImageWithPreview v-if="imageData.transferVouchertwo" :src="imageData.transferVouchertwo" ></ImageWithPreview>
                        <ImageWithPreview v-if="imageData.transferVoucherthere" :src="imageData.transferVoucherthere" ></ImageWithPreview>
                        <ImageWithPreview v-if="imageData.transferVoucherfour" :src="imageData.transferVoucherfour" ></ImageWithPreview>
                        <ImageWithPreview v-if="imageData.transferVoucherfive" :src="imageData.transferVoucherfive" ></ImageWithPreview>
                    </div>
                </a-form-model-item>
            </a-form-model>
        </a-spin>
    </a-modal>
</template>

<script>
import { onMounted, ref } from 'vue-demi';
import ImageWithPreview from '../../../components/imageWithPreview.vue';
import { getLaborCostTranservoucher } from '../../../api/projectUserManager';

    export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        recordId: {
            type: String | Number
        },
        projectId: {
            type: String | Number
        }
    },
    setup(props, context) {
        const imageData = ref({});
        const handleCancel = () => {
            context.emit("update:visible", false);
        };
        onMounted(async () => {
            loading.value = true;
            const res = await getLaborCostTranservoucher({
                Id: props.recordId,
                projectId: props.projectId
            });
            if(res.code === 200) {
                imageData.value = res.data;
            }
            loading.value = false;

        });
        const loading = ref(false);
        return {
            handleCancel,
            loading,
            imageData
        };
    },
    components: { ImageWithPreview }
}
</script>

<style lang="less" scoped>

</style>